import A_0002 from './GalleryPhotos/A_0002.jpg';
import C_0025 from './GalleryPhotos/C_0025.jpg';
import C_0115 from './GalleryPhotos/C_0115.jpg';
import D_0063 from './GalleryPhotos/D_0063.jpg';
import D_0174 from './GalleryPhotos/D_0174.jpg';
import E_0030 from './GalleryPhotos/E_0030.jpg';
import E_0761 from './GalleryPhotos/E_0761.jpg';
import A_0014 from './GalleryPhotos/A_0014.jpg';
import C_0039 from './GalleryPhotos/C_0039.jpg';
import C_0144 from './GalleryPhotos/C_0144.jpg';
import D_0110 from './GalleryPhotos/D_0110.jpg';
import D_0187 from './GalleryPhotos/D_0187.jpg';
import E_0046 from './GalleryPhotos/E_0046.jpg';
import E_0788 from './GalleryPhotos/E_0788.jpg';
import B_0034 from './GalleryPhotos/B_0034.jpg';
import C_0040 from './GalleryPhotos/C_0040.jpg';
import C_0146 from './GalleryPhotos/C_0146.jpg';
import D_0125 from './GalleryPhotos/D_0125.jpg';
import D_0188 from './GalleryPhotos/D_0188.jpg';
import E_0071 from './GalleryPhotos/E_0071.jpg';
import E_0842 from './GalleryPhotos/E_0842.jpg';
import B_0035 from './GalleryPhotos/B_0035.jpg';
import C_0048 from './GalleryPhotos/C_0048.jpg';
import C_0185 from './GalleryPhotos/C_0185.jpg';
import D_0141 from './GalleryPhotos/D_0141.jpg';
import D_0202 from './GalleryPhotos/D_0202.jpg';
import E_0072 from './GalleryPhotos/E_0072.jpg';
import B_0042 from './GalleryPhotos/B_0042.jpg';
import C_0053 from './GalleryPhotos/C_0053.jpg';
import D_0045 from './GalleryPhotos/D_0045.jpg';
import D_0149 from './GalleryPhotos/D_0149.jpg';
import D_0205 from './GalleryPhotos/D_0205.jpg';
import E_0553 from './GalleryPhotos/E_0553.jpg';
import B_0049 from './GalleryPhotos/B_0049.jpg';
import C_0060 from './GalleryPhotos/C_0060.jpg';
import D_0056 from './GalleryPhotos/D_0056.jpg';
import D_0161 from './GalleryPhotos/D_0161.jpg';
import D_0215 from './GalleryPhotos/D_0215.jpg';
import E_0593 from './GalleryPhotos/E_0593.jpg';
import B_0079 from './GalleryPhotos/B_0079.jpg';
import C_0087 from './GalleryPhotos/C_0087.jpg';
import D_0061 from './GalleryPhotos/D_0061.jpg';
import D_0162 from './GalleryPhotos/D_0162.jpg';
import D_0218 from './GalleryPhotos/D_0218.jpg';
import E_0705 from './GalleryPhotos/E_0705.jpg';

export const photos = [
  {
    src: A_0002,
    width: 4,
    height: 3
  },
  {
    src: E_0030,
    width: 4,
    height: 3
  },
  {
    src: A_0014,
    width: 4,
    height: 3
  },
  {
    src: E_0046,
    width: 4,
    height: 3
  },
  {
    src: E_0071,
    width: 4,
    height: 3
  },
  {
    src: E_0072,
    width: 4,
    height: 3
  },
  {
    src: B_0034,
    width: 4,
    height: 3
  },
  {
    src: B_0035,
    width: 4,
    height: 3
  },
  {
    src: B_0042,
    width: 2,
    height: 4
  },
  {
    src: B_0049,
    width: 4,
    height: 3
  },
  {
    src: B_0079,
    width: 4,
    height: 3
  },
  {
    src: C_0025,
    width: 4,
    height: 3
  },
  {
    src: C_0039,
    width: 2,
    height: 4
  },
  {
    src: C_0040,
    width: 4,
    height: 3
  },
  {
    src: C_0048,
    width: 4,
    height: 3
  },
  {
    src: C_0053,
    width: 4,
    height: 3
  },
  {
    src: C_0060,
    width: 2,
    height: 4
  },
  {
    src: C_0087,
    width: 2,
    height: 4
  },
  {
    src: C_0115,
    width: 4,
    height: 3
  },
  {
    src: C_0144,
    width: 4,
    height: 3
  },
  {
    src: C_0146,
    width: 4,
    height: 3
  },
  {
    src: C_0185,
    width: 4,
    height: 3
  },
  {
    src: E_0553,
    width: 4,
    height: 3
  },
  {
    src: D_0187,
    width: 4,
    height: 3
  },
  {
    src: D_0063,
    width: 4,
    height: 3
  },
  {
    src: D_0061,
    width: 4,
    height: 3
  },
  {
    src: D_0056,
    width: 4,
    height: 3
  },
  {
    src: D_0174,
    width: 4,
    height: 3
  },
  {
    src: E_0761,
    width: 4,
    height: 3
  },
  {
    src: D_0110,
    width: 4,
    height: 3
  },
  {
    src: E_0705,
    width: 4,
    height: 3
  },
  {
    src: E_0788,
    width: 4,
    height: 3
  },
  {
    src: D_0149,
    width: 4,
    height: 3
  },
  {
    src: D_0125,
    width: 4,
    height: 3
  },
  {
    src: D_0188,
    width: 4,
    height: 3
  },
  {
    src: E_0842,
    width: 4,
    height: 3
  },
  {
    src: D_0141,
    width: 4,
    height: 3
  },
  {
    src: D_0202,
    width: 4,
    height: 3
  },
  {
    src: E_0593,
    width: 4,
    height: 3
  },
  {
    src: D_0045,
    width: 4,
    height: 3
  },
  {
    src: D_0205,
    width: 4,
    height: 3
  },
  {
    src: D_0161,
    width: 4,
    height: 3
  },
  {
    src: D_0162,
    width: 4,
    height: 3
  },
  {
    src: D_0215,
    width: 4,
    height: 3
  },
  {
    src: D_0218,
    width: 4,
    height: 3
  }
];