import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../images/wedding/photos";
import SageTopLeft from "../images/SageTopLeft"

function WeddingGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
        <div style={{ marginBottom: `1.45rem`, pointerEvents: 'none' }} className="absolute top-0 w-60 md:w-4/12 max-w-lg z-10">
          <SageTopLeft />
        </div>
        <div className="z-0 m-3" style={{
        margin: '3.5em 0'
        }}>
          <div className="shadow-xl flex flex-col items-center justify-center relative md:mx-12" style={{ 
            background: 'rgb(248, 246, 242)',
            minHeight: '30vh',
            minWidth: '90vw'
          }}>
          <a href="/" className="hidden md:block"><h1 className="gold-text text-xl md:text-4xl absolute top-0 right-0 p-2" style={{ transform: 'rotate(90deg) translate(4em, -2.5em)' }}>JoeAndJayna.com</h1></a>
          <div className="text-body m-8 mt-12 text-center" style={{ maxWidth: '630px' }}>
            <div className="welcome-heading flex flex-col items-center justify-center">
              <p className="font-roman text-3xl md:text-6xl tracking-widest uppercase">We wed,<br />we danced,<br />we conquered!</p>
              {/* <p className="font-cursive text-2xl daggers">on</p> */}
              <p className="font-roman text-xl tracking-widest uppercase pb-4">June 3rd, 2022</p>
              <iframe className="min-w-full" height="400" src="https://www.youtube.com/embed/S87-xO5fzRY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <br />
            </div>
          </div>
        </div>
      </div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default WeddingGallery;
